<template>
  <div class="question-wizard-questions">
    <v-form data-vv-scope="questions">
      <v-layout class="row wrap">
        <v-flex xs12 class="mb-4">
          <!-- <draggable
            v-model="attributes"
            :handle="'.draggable-handle'"
            @change="sortAttributes"
          > -->
          <div
            v-for="(attribute, attributeIndex) in attributes"
            :key="'attribute-' + attributeIndex"
            class="mb-3"
          >
            <v-expansion-panel>
              <v-expansion-panel-content>
                <template slot="header">
                  <v-layout class="row no-wrap align-items-center">
                    <v-flex class="xs12 attribute-name">
                      <v-layout row class="align-center">
                        <!-- <font-awesome-icon
                          icon="grip-vertical"
                          class="sw-accent draggable-handle mr-3"
                          :class="{
                            disabled: !attributes.length > 1,
                          }"
                        /> -->
                        <v-flex class="sw-h5 font-weight-light"
                          >{{ attribute.name }}
                          <div
                            v-if="attribute.description"
                            class="caption grey--text"
                          >
                            {{ attribute.description }}
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <div class="mr-3">
                      <v-tooltip top>
                        <v-btn
                          slot="activator"
                          icon
                          class="my-0 mr-0"
                          @click.stop="showAttributeDialog(attribute.id)"
                        >
                          <v-icon color="grey" small>edit</v-icon>
                        </v-btn>
                        <span>{{ $t("common.edit") }}</span>
                      </v-tooltip>
                    </div>
                  </v-layout>
                </template>
                <v-card color="grey lighten-5" class="pa-2">
                  <template
                    v-if="attribute.details && attribute.details.description"
                  >
                    <div
                      v-html="attribute.details.description"
                      class="sw-content-typography pt-2 pb-4 clearfix"
                    ></div>
                  </template>
                  <div
                    v-if="
                      attribute.type === 'options' && attribute.is_collection
                    "
                  >
                    <div
                      v-for="(option, optionIndex) in attribute.options"
                      :key="`option-${optionIndex}`"
                    >
                      <v-checkbox
                        :label="option.name"
                        hide-details
                        class="shrink mt-0"
                        disabled
                      />
                    </div>
                    <div v-if="!attribute || !attribute.options[0]">
                      {{ $t("no-options-added-yet") }}!
                    </div>
                  </div>
                  <div
                    v-if="
                      attribute.type === 'options' && !attribute.is_collection
                    "
                  >
                    <v-radio-group column>
                      <template
                        v-for="(option, optionIndex) in attribute.options"
                      >
                        <v-radio
                          :key="`option-${optionIndex}`"
                          :label="option.name"
                          hide-details
                          disabled
                        />
                      </template>
                    </v-radio-group>
                    <div v-if="!attribute.options.length">
                      {{ $t("no-options-added-yet") }}!
                    </div>
                  </div>
                  <div v-if="attribute.type === 'varchar'">
                    <v-text-field
                      :placeholder="$t('textFieldPlaceholder')"
                      hide-details
                      solo
                      disabled
                    />
                  </div>
                  <div v-if="attribute.type === 'text'">
                    <v-textarea
                      :placeholder="$t('textFieldPlaceholder')"
                      no-resize
                      hide-details
                      solo
                      disabled
                    />
                  </div>
                  <div v-if="attribute.type === 'datetime'">
                    <v-text-field
                      :placeholder="'DD.MM.YYYY'"
                      hide-details
                      solo
                      disabled
                    />
                  </div>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </div>
          <!-- </draggable> -->
        </v-flex>
        <v-flex xs12 class="text-center">
          <v-btn
            fab
            large
            class="sw-accent-bg sw-on-accent"
            @click="showAttributeDialog()"
          >
            <v-icon dark>add</v-icon>
          </v-btn>
          <div class="caption">
            {{ $t("question_wizard.add_attribute_hint") }}
          </div>
        </v-flex>
        <v-flex
          v-if="errors && errors.collect('questions.questions').length"
          xs12
        >
          <ErrorMessages
            :error-messages="errors.collect('questions.questions')"
          />
        </v-flex>
      </v-layout>
    </v-form>

    <QuestionsAttributeModal
      v-if="category.id"
      :group-id="groupId"
      :groupPluginId="plugin.id"
      :categoryId="category.id"
      ref="attributeRef"
      @attribute-created="onAttributeCreated()"
      @attribute-updated="$emit('attribute-updated')"
    />
  </div>
</template>

<script>
// import draggable from "vuedraggable";
import QuestionsAttributeModal from "@/components/QuestionWizard/QuestionsAttributeModal.vue";

export default {
  inject: ["parentValidator"],
  props: {
    groupId: [String, Number],
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    plugin() {
      return this.$store.getters.questionWizardPlugin;
    },
    category() {
      return this.$store.getters.questionWizardCategory;
    },
    attributes: {
      get() {
        return this.$store.getters.questionWizardAttributes;
      },
      set(val) {
        this.$store.dispatch("setQuestionWizardAttributes", val);
      },
    },
  },
  components: {
    // draggable,
    QuestionsAttributeModal,
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    // async sortAttributes() {
    //   try {
    //     if (this.attributes.length <= 1) {
    //       return;
    //     }

    //     const newOrder = this.attributes.map((el) => el.id).toString();
    //     const specs = [this.groupId, { new_order: newOrder }];

    //     this.isLoading = true;

    //     await this.$api.groupUserAttributes.sort(...specs);

    //     this.isLoading = false;
    //   } catch (error) {
    //     this.isLoading = false;

    //     if (error) {
    //       this.errorMessageShow(error);
    //     }
    //   }
    // },
    async onAttributeCreated() {
      this.$emit("attribute-created");
    },
    async showAttributeDialog(attributeId) {
      this.$refs.attributeRef.showAttributeDialog(attributeId);
    },
  },
};
</script>

<style lang="scss" scoped>
.attribute-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .sortable-ghost {
//   opacity: 0;
// }

// .draggable-handle.disabled {
//   color: grey !important;
//   pointer-events: none;
// }
</style>
