<template>
  <div class="question-wizard-question-settings">
    <v-form data-vv-scope="settings">
      <v-layout row wrap class="mb-5">
        <v-flex xs6 class="pr-5">
          <TranslatableTooltip
            :content="$t('question_wizard.name_label_tooltip')"
          >
            <div class="sw-h5">
              {{ $t("question_wizard.name_label") }}
            </div>
          </TranslatableTooltip>
          <v-text-field
            v-model="plugin.name"
            v-validate.disable="'required|min:3'"
            data-vv-name="name"
            :data-vv-as="$t('question_wizard.name_data_vv_as')"
            :placeholder="$t('question_wizard.name_label_placeholder')"
            hide-details
          />
          <ErrorMessages :error-messages="errors.collect('settings.name')" />
        </v-flex>
      </v-layout>

      <v-layout row wrap class="mb-5">
        <v-flex xs4 class="pr-5">
          <v-card flat class="fill-height sw-primary-bg">
            <v-card-text class="py-5 text-center">
              <TranslatableTooltip
                :content="
                  $t(
                    'question_wizard.questionwizard_introduction_banner_label_tooltip',
                  )
                "
                class="center"
              >
                <div class="mb-2 sw-h5">
                  {{
                    $t(
                      "question_wizard.questionwizard_introduction_banner_label",
                    )
                  }}
                </div>
              </TranslatableTooltip>
              <MediaManager
                v-model="plugin.questionwizard_introduction_banner"
                :media-type="'images'"
                label=""
              />
            </v-card-text>
            <div class="px-4 pb-3 sw-caption text-center">
              {{
                $t("question_wizard.questionwizard_introduction_banner_hint")
              }}
            </div>
          </v-card>
        </v-flex>
        <v-flex xs4 class="pl-5 offset-xs2">
          <v-card flat class="fill-height sw-primary-bg">
            <v-card-text class="py-5 text-center">
              <TranslatableTooltip
                :content="
                  $t(
                    'question_wizard.questionwizard_thank_you_banner_label_tooltip',
                  )
                "
                class="center"
              >
                <div class="mb-2 sw-h5">
                  {{
                    $t("question_wizard.questionwizard_thank_you_banner_label")
                  }}
                </div>
              </TranslatableTooltip>
              <MediaManager
                v-model="plugin.questionwizard_thank_you_banner"
                :media-type="'images'"
                label=""
              />
            </v-card-text>
            <div class="px-4 pb-3 sw-caption text-center">
              {{ $t("question_wizard.questionwizard_thank_you_banner_hint") }}
            </div>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row wrap class="mb-5">
        <v-flex xs6 class="pr-5">
          <TranslatableTooltip
            :content="
              $t(
                'question_wizard.questionwizard_introduction_headline_label_tooltip',
              )
            "
          >
            <div class="sw-h5">
              {{
                $t("question_wizard.questionwizard_introduction_headline_label")
              }}
            </div>
          </TranslatableTooltip>
          <v-text-field
            v-model="plugin.questionwizard_introduction_headline"
            v-validate.disable="'max:255'"
            data-vv-name="questionwizard_introduction_headline"
            :data-vv-as="
              $t(
                'question_wizard.questionwizard_introduction_headline_data_vv_as',
              )
            "
            :placeholder="
              $t(
                'question_wizard.questionwizard_introduction_headline_placeholder',
              )
            "
            counter="255"
            hide-details
          />
          <ErrorMessages
            :error-messages="
              errors.collect('settings.questionwizard_introduction_headline')
            "
          />
        </v-flex>
        <v-flex xs6 class="pl-5">
          <TranslatableTooltip
            :content="
              $t(
                'question_wizard.questionwizard_thank_you_headline_label_tooltip',
              )
            "
          >
            <div class="sw-h5">
              {{
                $t("question_wizard.questionwizard_thank_you_headline_label")
              }}
            </div>
          </TranslatableTooltip>
          <v-text-field
            v-model="plugin.questionwizard_thank_you_headline"
            v-validate.disable="'max:255'"
            data-vv-name="questionwizard_thank_you_headline"
            :data-vv-as="
              $t('question_wizard.questionwizard_thank_you_headline_data_vv_as')
            "
            :placeholder="
              $t(
                'question_wizard.questionwizard_thank_you_headline_placeholder',
              )
            "
            counter="255"
            hide-details
          />
          <ErrorMessages
            :error-messages="
              errors.collect('settings.questionwizard_thank_you_headline')
            "
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap class="mb-5">
        <v-flex xs6 class="pr-5">
          <TranslatableTooltip
            :content="
              $t(
                'question_wizard.questionwizard_introduction_content_label_tooltip',
              )
            "
          >
            <div class="sw-h5">
              {{
                $t("question_wizard.questionwizard_introduction_content_label")
              }}
            </div>
          </TranslatableTooltip>
          <v-textarea
            v-model="plugin.questionwizard_introduction_content"
            v-validate.disable="'max:255'"
            data-vv-name="questionwizard_introduction_content"
            :data-vv-as="
              $t(
                'question_wizard.questionwizard_introduction_content_data_vv_as',
              )
            "
            :placeholder="
              $t(
                'question_wizard.questionwizard_introduction_content_placeholder',
              )
            "
            counter="255"
            no-resize
            hide-details
          />
          <ErrorMessages
            :error-messages="
              errors.collect('settings.questionwizard_introduction_content')
            "
          />
        </v-flex>
        <v-flex xs6 class="pl-5">
          <TranslatableTooltip
            :content="
              $t(
                'question_wizard.questionwizard_thank_you_content_label_tooltip',
              )
            "
          >
            <div class="sw-h5">
              {{ $t("question_wizard.questionwizard_thank_you_content_label") }}
            </div>
          </TranslatableTooltip>
          <v-textarea
            v-model="plugin.questionwizard_thank_you_content"
            v-validate.disable="'max:255'"
            data-vv-name="questionwizard_thank_you_content"
            :data-vv-as="
              $t('question_wizard.questionwizard_thank_you_content_data_vv_as')
            "
            :placeholder="
              $t('question_wizard.questionwizard_thank_you_content_placeholder')
            "
            counter="255"
            no-resize
            hide-details
          />
          <ErrorMessages
            :error-messages="
              errors.collect('settings.questionwizard_thank_you_content')
            "
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap class="mb-5">
        <v-flex xs6 class="pr-5">
          <div class="sw-h5">
            {{ $t("question_wizard.questionwizard_start_button_label") }}
          </div>
          <v-text-field
            v-model="plugin.questionwizard_start_button"
            v-validate.disable="'max:255'"
            data-vv-name="questionwizard_start_button"
            :data-vv-as="
              $t('question_wizard.questionwizard_start_button_data_vv_as')
            "
            :placeholder="
              $t('question_wizard.questionwizard_start_button_placeholder')
            "
            counter="255"
            hide-details
          />
          <ErrorMessages
            :error-messages="
              errors.collect('settings.questionwizard_start_button')
            "
          />
        </v-flex>
        <v-flex xs6 class="pl-5">
          <div class="sw-h5">
            {{ $t("question_wizard.questionwizard_finish_button_label") }}
          </div>
          <v-text-field
            v-model="plugin.questionwizard_finish_button"
            v-validate.disable="'max:255'"
            data-vv-name="questionwizard_finish_button"
            :data-vv-as="
              $t('question_wizard.questionwizard_finish_button_data_vv_as')
            "
            :placeholder="
              $t('question_wizard.questionwizard_finish_button_placeholder')
            "
            counter="255"
            hide-details
          />
          <ErrorMessages
            :error-messages="
              errors.collect('settings.questionwizard_finish_button')
            "
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap class="mb-5">
        <v-flex xs6 class="pr-5">
          <v-layout row wrap>
            <v-flex xs12>
              <div class="mb-4 sw-h5">
                {{
                  $t("question_wizard.questionwizard_active_from_till_label")
                }}
              </div>
              <date-picker
                v-model="plugin.questionwizard_active_from"
                width="100%"
                :lang="datePickerLang"
                type="datetime"
                :first-day-of-week="1"
                :format="'MMMM DD, YYYY H:mm'"
                :minute-step="5"
                v-validate.disable="'required'"
                name="questionwizard_active_from"
                :data-vv-as="
                  $t('question_wizard.questionwizard_active_from_data_vv_as')
                "
                :editable="false"
                @input="updateActiveTill"
                append-to-body
              />
              <ErrorMessages
                :error-messages="
                  errors.collect('settings.questionwizard_active_from')
                "
              />
            </v-flex>
            <v-flex xs12>
              <date-picker
                v-model="plugin.questionwizard_active_till"
                width="100%"
                :lang="datePickerLang"
                type="datetime"
                :format="'MMMM DD, YYYY H:mm'"
                :minute-step="5"
                :not-before="plugin.questionwizard_active_from"
                v-validate.disable="'required'"
                name="questionwizard_active_till"
                :data-vv-as="
                  $t('question_wizard.questionwizard_active_till_data_vv_as')
                "
                :editable="false"
                append-to-body
              />
              <ErrorMessages
                :error-messages="
                  errors.collect('settings.questionwizard_active_till')
                "
              />
              <div class="caption grey--text">
                {{ $t("current-timezone") }}:
                {{ userTimezone }}
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6 class="pl-5">
          <TranslatableTooltip
            :content="
              $t('question_wizard.questionwizard_closed_content_label_tooltip')
            "
          >
            <div class="sw-h5">
              {{ $t("question_wizard.questionwizard_closed_content_label") }}
            </div>
          </TranslatableTooltip>
          <v-textarea
            v-model="plugin.questionwizard_closed_content"
            v-validate.disable="'required|max:255'"
            data-vv-name="questionwizard_closed_content"
            :data-vv-as="
              $t('question_wizard.questionwizard_closed_content_data_vv_as')
            "
            :placeholder="
              $t('question_wizard.questionwizard_closed_content_placeholder')
            "
            counter="255"
            no-resize
            hide-details
          />
          <ErrorMessages
            :error-messages="
              errors.collect('settings.questionwizard_closed_content')
            "
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs6 class="pr-5">
          <div class="sw-h5">
            {{ $t("question_wizard.settings_label") }}
          </div>
          <TranslatableTooltip
            :content="
              $t(
                'question_wizard.questionwizard_destination_enabled_label_tooltip',
              )
            "
          >
            <v-switch
              v-if="messageTypes.includes('push') && ionicAppSidemenu"
              v-model="plugin.questionwizard_destination_enabled"
              class="mb-0"
              hide-details
            >
              <template slot="label">
                <div class="black--text">
                  {{
                    $t(
                      "question_wizard.questionwizard_destination_enabled_label",
                    )
                  }}
                </div>
              </template>
            </v-switch>
          </TranslatableTooltip>
          <TranslatableTooltip
            :content="
              $t(
                'question_wizard.questionwizard_push_message_enabled_label_tooltip',
              )
            "
          >
            <v-switch
              v-if="messageTypes.includes('push')"
              v-model="plugin.questionwizard_push_message_enabled"
              class="mb-0"
              hide-details
            >
              <template slot="label">
                <div class="black--text">
                  {{
                    $t(
                      "question_wizard.questionwizard_push_message_enabled_label",
                    )
                  }}
                </div>
              </template>
            </v-switch>
          </TranslatableTooltip>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import moment from "moment-timezone";
import DatePicker from "vue2-datepicker";

export default {
  inject: ["parentValidator"],
  data: () => ({
    messageTypes: [],
  }),
  computed: {
    plugin: {
      get() {
        return this.$store.getters.questionWizardPlugin;
      },
      set(val) {
        this.$store.dispatch("setQuestionWizardPlugin", val);
      },
    },
    ionicAppSidemenu() {
      return this.$store.getters.questionWizardIonicAppSidemenu;
    },
  },
  components: {
    DatePicker,
  },
  created() {
    this.$validator = this.parentValidator;
    this.getMessageTypes();
  },
  methods: {
    async getMessageTypes() {
      try {
        const response = await this.$api.settings.public("group.message_types");

        if (!response || !response.data.data.value) return;

        this.messageTypes = response.data.data.value.split(",");
      } catch (error) {}
    },
    updateActiveTill() {
      const activeFrom = this.plugin.questionwizard_active_from;
      const activeTill = this.plugin.questionwizard_active_till;

      const minActiveTill = moment
        .tz(activeFrom, this.userTimezone)
        .add(24, "hours")
        .toISOString();

      if (!activeTill) {
        this.plugin.questionwizard_active_till = minActiveTill;
        return;
      }

      const isSameOrBefore = moment(activeFrom).isSameOrBefore(activeTill);

      if (!isSameOrBefore) {
        this.plugin.questionwizard_active_till = minActiveTill;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
