<template>
  <v-layout row wrap class="text-center">
    <v-flex xs12 class="mb-5">
      <div class="sw-h3 font-weight-light">
        {{ $t("question_wizard.questions_success") }}
      </div>
    </v-flex>
    <v-flex xs12 class="text-center">
      <v-btn
        round
        large
        class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
        @click="goToListView()"
      >
        {{ $t("question_wizard.go_to_list_view_btn_text") }}
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    groupId: [String, Number],
  },
  methods: {
    goToListView() {
      this.$router
        .push({
          name: "questions",
          params: {
            group_id: this.groupId,
          },
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
