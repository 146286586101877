<template>
  <div class="question-wizard-wizard-menu">
    <v-form v-if="menuDestination" data-vv-scope="menu">
      <v-layout row wrap>
        <v-flex xs7 class="pr-5">
          <v-layout row wrap>
            <v-flex xs12 class="mb-5">
              <div class="sw-h5">
                {{ $t("question_wizard.destination_name_label") }}
              </div>
              <v-text-field
                v-model="menuDestination.name"
                v-validate.disable="'required'"
                data-vv-name="name"
                :data-vv-as="$t('question_wizard.destination_name_data_vv_as')"
                :placeholder="
                  $t('question_wizard.destination_name_placeholder')
                "
                hide-details
              ></v-text-field>
              <ErrorMessages :error-messages="errors.collect('menu.name')" />
            </v-flex>
            <v-flex xs12 class="mb-5">
              <TranslatableTooltip
                :content="$t('question_wizard.destination_icon_label_tooltip')"
              >
                <div class="mb-4 sw-h5">
                  {{ $t("question_wizard.destination_icon_label") }}
                </div>
              </TranslatableTooltip>
              <IconSelector
                v-model="menuDestination.icon"
                :label="$t('question_wizard.destination_icon_label')"
                class="mb-3"
              />
            </v-flex>
            <v-flex xs12>
              <div class="mb-4 sw-h5">
                {{ $t("question_wizard.promoted_destination_label") }}
              </div>
            </v-flex>
            <v-flex xs12 mb-4>
              <TranslatableTooltip
                :content="
                  $t('question_wizard.promoted_destination_label_tooltip')
                "
              >
                <v-switch v-model="menuDestination.promoted" class="my-0">
                  <template slot="label">
                    <div class="black--text">
                      {{
                        menuDestination.promoted
                          ? $t("common.yes")
                          : $t("common.no")
                      }}
                    </div>
                  </template>
                </v-switch>
              </TranslatableTooltip>
            </v-flex>
            <v-flex xs12>
              <div class="mb-4 sw-h5">
                {{ $t("question_wizard.visibility_label") }}
              </div>
            </v-flex>
            <v-flex xs12 class="mb-5">
              <v-radio-group
                v-model="menuDestination.is_public"
                row
                class="ma-0"
                hide-details
              >
                <v-layout row wrap>
                  <v-flex xs4 class="pr-4">
                    <v-radio :value="null">
                      <template slot="label">
                        <div class="black--text">
                          {{ $t("question_wizard.visibility_all_label") }}
                        </div>
                      </template>
                    </v-radio>
                    <div class="sw-caption pt-3 pl-4 ml-2">
                      {{ $t("question_wizard.visibility_all_hint") }}
                    </div>
                  </v-flex>
                  <v-flex xs4 class="px-4">
                    <v-radio :value="false">
                      <template slot="label">
                        <div class="black--text">
                          {{
                            $t("question_wizard.visibility_authorized_label")
                          }}
                        </div>
                      </template>
                    </v-radio>
                    <div class="sw-caption pt-3 pl-4 ml-2">
                      {{ $t("question_wizard.visibility_authorized_hint") }}
                    </div>
                  </v-flex>
                  <v-flex xs4 class="pl-4">
                    <v-radio :value="true">
                      <template slot="label">
                        <div class="black--text">
                          {{
                            $t("question_wizard.visibility_unauthorized_label")
                          }}
                        </div>
                      </template>
                    </v-radio>
                    <div class="sw-caption pt-3 pl-4 ml-2">
                      {{ $t("question_wizard.visibility_unauthorized_hint") }}
                    </div>
                  </v-flex>
                </v-layout>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs5 class="pl-5">
          <v-layout row wrap>
            <v-flex xs8>
              <TranslatableTooltip
                :content="$t('question_wizard.destination_order_label_tooltip')"
              >
                <div class="mb-4 sw-h5">
                  {{ $t("question_wizard.destination_order_label") }}
                </div>
              </TranslatableTooltip>
              <div class="menu-draggable px-4">
                <vue-draggable
                  v-model="ionicAppSidemenu.destinations"
                  :handle="'.draggable-handle'"
                >
                  <div
                    v-for="(destination, i) in ionicAppSidemenu.destinations"
                    :key="`destination-${i}`"
                    id="vue-draggable"
                    class="menu-items"
                  >
                    <v-layout row class="py-4">
                      <v-flex xs2 class="text-center">
                        <font-awesome-icon
                          v-if="destination.editable"
                          icon="grip-vertical"
                          class="draggable-handle sw-accent"
                        ></font-awesome-icon>
                      </v-flex>
                      <v-flex xs2 class="text-center">
                        <font-awesome-icon
                          v-if="destination.icon"
                          :icon="transformIcon(destination.icon)"
                          class="sw-accent"
                        ></font-awesome-icon>
                      </v-flex>
                      <v-flex xs8>
                        <div class="sw-body-1 name">
                          {{
                            destination.name ||
                            $t("question_wizard.new_page_name")
                          }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>
                </vue-draggable>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  inject: ["parentValidator"],
  computed: {
    ionicAppSidemenu: {
      get() {
        return this.$store.getters.questionWizardIonicAppSidemenu;
      },
      set(val) {
        this.$store.dispatch("setQuestionWizardIonicAppSidemenu", val);
      },
    },
    menuDestination() {
      if (!this.ionicAppSidemenu || !this.ionicAppSidemenu.destinations) {
        return;
      }
      return this.ionicAppSidemenu.destinations.find((el) => el.editable);
    },
  },
  components: {
    "vue-draggable": draggable,
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    transformIcon(icon) {
      return icon ? icon.split(" ") : [];
    },
  },
};
</script>

<style lang="scss">
.question-wizard-wizard-menu {
  .menu-draggable {
    border: 1px solid $accent;
  }

  .menu-items:not(:last-child) {
    border-bottom: 1px solid $accent;
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .v-input--radio-group .v-input__control {
    width: 100%;
  }
}
</style>
